
import {
    defineStore
} from 'pinia'

import {
    getCityData
} from '@/api/modules/global.api.js'

// 城市
export const useCity = defineStore('city', {
    state: () => {
        return {
            city: [
                {
                    id: '-1',
                    title: '所在区域'
                }
            ], // 城市列表
            isRequest: false,
        }
    },
    /* 永久行存储 */
    persist: {
        enabled: true, // 开启存储
        // **strategies: 指定存储位置以及存储的变量都有哪些,该属性可以不写，不写的话默认是存储到sessionStorage里边，默认存储state里边的所有数据**
        strategies: [
            { storage: sessionStorage, paths: ['city'] }
            // storage 存储到哪里 sessionStorage/localStorage
            // paths是一个数组,要存储缓存的变量,当然也可以写多个
            // paths如果不写就默认存储state里边的所有数据，如果写了就存储指定的变量
        ]
    },
    actions: {
        async getCityData() {
            if (this.city.length > 1) {
                return
            }
            if (this.isRequest) return
            this.isRequest = true
            let res = await getCityData()
            let {
                status,
                data
            } = res
            if (status === 1) {
                let city = handerCityData(data.region, 0) // 
                this.city.push(...city)
                this.isRequest = false
            }
        }
    }
})


// 处理数据
function handerCityData(data = [], id) {
    return data.reduce((prev, cur) => {
        if (cur.parent_id == id) {
            // 是父亲
            cur.children = []
            let children = handerCityData(data, cur.id)
            if (children.length > 0) {
                cur.children = children
            }
            prev.push(cur)
        } else {
            return prev
        }
        return prev
    }, [])
}