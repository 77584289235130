


import axiso from '../axios'

// 地区获取
export const getCityData = () => {
    return axiso.post('/region')
}



