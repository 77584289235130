import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac13046c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "trade_company" }
const _hoisted_2 = { class: "search-warp" }
const _hoisted_3 = { class: "city-warp" }
const _hoisted_4 = { class: "slot" }
const _hoisted_5 = { class: "search-box" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = {
  key: 0,
  class: "search-nav"
}
const _hoisted_8 = { class: "nav-list" }
const _hoisted_9 = ["onClick"]

import { ref, computed, reactive } from "vue";
import { onBeforeRouteLeave, useRoute } from "vue-router";
import { useCity } from "@/pinia/api/useCity.js";
import { storeToRefs } from "pinia";
import { useNews } from "@/pinia/api/useNews"; // pinia  news
// 行情的城市
import { useHqCityS } from "@/pinia/base/useHqCity.js";

export default {
  props: {
  placeholder: String,
  default: () => "",
  type: String,
  setShowTitle: Function,
  cityType: String,
},
  emits: ["search", "companyChangeCity", "changeQy"],
  setup(__props, { expose, emit }) {

const props = __props

const hqCityStore = useHqCityS();
hqCityStore.initHqCity(); // 初始化

const store = useNews(); // pinia store
const cityStore = useCity();
cityStore.getCityData(); // 初始化city数据
const { city } = storeToRefs(cityStore);
// 向父元素发送事件

// props


//修改关键字的方法
expose({
  setKeyWords(key) {
    keyWords.value = key;
  },
});

// 切换栏目的时候触发
const checkColumn = (row) => {
  let { id, name } = row;
  store.column_id = id;
  store.checkNewsCloumn({ page: 1 });
  store.clearParams(); // 在切换时就要清空params参数
  props.setShowTitle(name);
};

const route = useRoute();

const tabsState = reactive({
  // tabs
  tabsList: [
    { id: "11", name: "最新供求" },
    { id: "1", name: "仓库物流" },
    {
      id: "3",
      name: "调价信息",
    },
    {
      id: "12",
      name: "通知公告",
    },
  ],
  // 当前展示
  curTabs: computed(() => {
    const ind = tabsState.tabsList.findIndex((v) => v.id == store.column_id);
    return tabsState.tabsList[ind].id;
  }),
});

// 点击搜索的时候 触发的事件
const keyWords = ref("");
const search = () => {
  emit("search", keyWords);
};

// 所在区域
const changeQy = () => {
  if (cityDate.currentCityCode == -1) {
    cityDate.currentChildrenCityCode = "";
    emit("changeQy", false);
  }
};

// 保存关于下拉框 城市的数据
const cityDate = reactive({
  currentCityCode: "",
  children: [],
  currentChildrenCityCode: "",
  city: computed(() => {
    if (props.cityType == "price") {
      return hqCityStore.city;
    } else {
      return city.value;
    }
  }),
});

//  点击城市的时候更换成改城市相对应的下级城市
cityDate.children = computed(() => {
  if (cityDate.currentCityCode === undefined) {
    return [
      {
        title: "所在市",
        id: "-000011110",
      },
    ];
  } else {
    const children = cityDate.city.filter(
      (item) => item.id === cityDate.currentCityCode
    );
    return children.length && children[0].children;
  }
});

// 市改变的时候触发
const changeCity = (id) => {
  if (props.type === "公司推荐") {
    emit("companyChangeCity", id);
  } else if (props.type === "电子杂志") {
    emit("companyChangeCity", id);
  } else {
    store.params.regionId = id;
    store.checkNewsCloumn({ page: 1 });
  }
};

// 当路由离开后
onBeforeRouteLeave(() => {
  store.clearParams();
  return true;
});

return (_ctx, _cache) => {
  const _component_el_option = _resolveComponent("el-option")
  const _component_el_select = _resolveComponent("el-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_select, {
          modelValue: _unref(cityDate).currentCityCode,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_unref(cityDate).currentCityCode) = $event)),
          class: "m-2",
          placeholder: "所在区域",
          size: "small",
          onChange: changeQy
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cityDate).city, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.title,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_el_select, {
          modelValue: _unref(cityDate).currentChildrenCityCode,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_unref(cityDate).currentChildrenCityCode) = $event)),
          class: "m-2",
          placeholder: "所在市",
          size: "small",
          onChange: changeCity
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(cityDate).children, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.title,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "date")
      ]),
      _createElementVNode("div", _hoisted_5, [
        _withDirectives(_createElementVNode("input", {
          type: "text",
          class: "search",
          placeholder: props.placeholder,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((keyWords).value = $event))
        }, null, 8, _hoisted_6), [
          [_vModelText, keyWords.value]
        ]),
        _createElementVNode("button", {
          class: "search-btn",
          onClick: search
        }, "搜索")
      ])
    ]),
    (_unref(route).path.includes('trade'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("ul", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabsState).tabsList, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                class: _normalizeClass(item.id == _unref(tabsState).curTabs ? 'acitve-warp active' : ''),
                key: item.id,
                onClick: $event => (checkColumn(item))
              }, _toDisplayString(item.name), 11, _hoisted_9))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

}