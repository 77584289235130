import {
    defineStore
} from 'pinia'
import {
    getNewsData,
    getNewsMore
} from '@/api/modules/news_api.js'
import {
    uniqBy
} from 'lodash'
import moreHandler, {
    moreNewsHandler
} from '../utils/moreHandler.js'

export const useNews = defineStore('news', {
    state: () => {
        return {
            newsList: [],
            total: 0, // 总数
            loading: false, // loading
            column_id: 11, // 默认为最新供求，  //栏目id 最新供求11， 仓储物流1， 人才招聘13
            params: {
                regionId: '', // 地区
                type: 0,
                info: '', // 关键字
                start: '', //开始时间
                end: '', // 结束时间
            },
            flag: false, // 节流
            homeParmas: {
                regionId: ''
            }
        }
    },
    /* 永久行存储 */
    persist: {
        enabled: true, // 开启存储
        // **strategies: 指定存储位置以及存储的变量都有哪些,该属性可以不写，不写的话默认是存储到sessionStorage里边，默认存储state里边的所有数据**
        strategies: [
            { storage: sessionStorage, paths: ['column_id'] }
            // storage 存储到哪里 sessionStorage/localStorage
            // paths是一个数组,要存储缓存的变量,当然也可以写多个
            // paths如果不写就默认存储state里边的所有数据，如果写了就存储指定的变量
        ]
    },
    getters: {

    },
    actions: {

        // 清空查询参数
        clearParams() {

        },
        // 截取数组的前8个
        sliceArrTo8(arr = []) {
            const result = arr.slice(0, 8)
            return result
        },
        // 渲染数据的方法
        async initNewsList(params, flag) {
            params.limit = 20 //一页展示20条数据
            params.column_id = this.column_id
            params.type = '0'
            params.info = this.params.info
            // 如果已经发送过了 就不发送请求了
            if (this.newsList.length == 0 || params.page == 1) {
                try {
                    this.loading = true
                    let res = await getNewsData(params)
                    let {
                        status,
                        data
                    } = res
                    if (status === 1) {
                        const list = data.news.map(item => {
                            item.action = 'more'
                            return item
                        })
                        this.newsList = list
                        this.total = data.total
                        this.loading = false
                    }
                } catch (error) {
                    console.log(error);
                }
            }
            // 如果传了一个flag 强制发送请求 那么就再次发送请求
            if (!flag) return
            try {
                this.loading = true
                let res = await getNewsData(params)
                let {
                    status,
                    data
                } = res
                if (status === 1) {
                    const list = data.news.map(item => {
                        item.action = 'more'
                        return item
                    })
                    this.newsList = data.news
                    this.total = data.total
                    this.loading = false
                }
            } catch (error) {
                console.log(error);
            }
        },




        // 切换栏目的时候触发
        async checkNewsCloumn(params) {
            params = params || {}
            this.loading = true
            params.limit = 20
            params.column_id = this.column_id

            let res = await getNewsData({
                ...params,
                ...this.params
            })
            let {
                status,
                data
            } = res
            if (status === 1) {
                const list = data.news.map(item => {
                    item.action = 'more'
                    return item
                })
                this.newsList = list
                this.total = data.total
                this.loading = false
            }
        },

        // 搜素的方法
        async searchHandler(params) {
            this.loading = true
            params.limit = 8
            params.column_id = this.column_id
            let res = await getNewsData({
                ...params,
                ...this.homeParmas
            })
            let {
                status,
                data
            } = res
            if (status === 1) {
                const list = data.news.map(item => {
                    item.action = 'more'
                    return item
                })
                this.homeNewsList = list
                this.loading = false
            }
        },

        // 点击更多报价
        async getMoreNews(params) {
            this.loading = true
            let {
                target
            } = params
            if (this.flag) {
                return
            }
            this.flag = true
            params.type_id = this.column_id ? this.column_id : 0
            delete params.target
            let res = await getNewsMore(params)
            let {
                status,
                data
            } = res
            if (status === 1) {
                if (target !== 'home') {
                    this.newsList = moreNewsHandler(this.newsList, data, params.id, params.index)
                } else {
                    this.homeNewsList = moreNewsHandler(this.homeNewsList, data, params.id, params.index)
                }
                this.flag = false
                this.loading = false
            } else {
                this.loading = false
            }
        },

        // 点击收起时触发
        clickPuck(row, target) {
            if (target !== 'home') {
                this.newsList = this.newsList.filter(item => {
                    if (item.companyId == row.companyId && item.isChildren) {
                        return false
                    } else {
                        if (item.companyId == row.companyId) {
                            item.action = 'more'
                        }
                        return true
                    }
                })
            } else {
                this.homeNewsList = this.homeNewsList.filter(item => {
                    if (item.companyId == row.companyId && item.isChildren) {
                        return false
                    } else {
                        if (item.companyId == row.companyId) {
                            item.action = 'more'
                        }
                        return true
                    }
                })
            }
        },
    }
})