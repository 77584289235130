

import http from '../axios'

// 咨询中心，轮播消息
export const getMovingData = () => {
    return http.get('/home/moving')
}


// 分类接口
export const getClassIfy = () => {
    return http.post('/type')
}

// 分类组
export const getClassifyArr = (params) => {
    return http.post('/home/group/type', params)
}

// 次分类
export const getChildrenData = params => {
    return http.post('/home/bout/type', params)
}


// 主页接口
export const getHomeData = () => {
    return http.post('/home')
}

// 企业推荐接口
export const getCompanyList = (params) => {
    return http.post('/home/company', params)
}


// 广告接口
export const $getAdvData = params => {
    return http.post('/advs', params)
}


//今日动态 
export const $getTodayData = params => {
    return http.post('/today/data', params)
}


// 行情的城市接口
export const $getHqCityData = params => {
    return http.post('/content/region', params)
}


// 主页钢市动态的接口 带图片
export const $getSteelData = params => {
    return http.post('/home/steel/dynamic', params)
}

// 主页贸易商机下面两个板块的详情接口
export const $getMeetingDeatailData = params => {
    return http.post('/hots/info', params)
}



// 热门搜索词
export const $hotSearch = (params) => {
    return http.post('/hot/words', params)
}

// 点击热门搜索词时的搜索接口
export const $searchToHotWords = params => {
    return http.post('/search', params)
}
